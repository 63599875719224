import { useContext, forwardRef } from "react";
import { UserContext } from "../Contexts/UserContext";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Typography,
//   makeStyles,
//   Slide,
// } from "@material-ui/core";
const Transition = forwardRef(function Transition(props, ref) {
  return (<></>)
  //  <Slide direction="up" ref={ref} {...props} />;
});
const SessionTimeoutDialog = ({ open, countdown, onContinue, logout }) => {
  return (<></>
    // <Dialog open={open} TransitionComponent={Transition}>
    //   <DialogTitle>Sessão prestes a expirar</DialogTitle>
    //   <DialogContent>
    //     <Typography variant="body2">
    //       A sessão atual expira em <span>{countdown}</span> segundos.
    //     </Typography>
    //     <Typography variant="body2">
    //       Você gostaria de continuar na sessão?
    //     </Typography>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={logout}
    //       style={{ background: "#e0e0e0" }}
    //       variant="contained"
    //     >
    //       Não
    //     </Button>
    //     <Button onClick={onContinue} color="primary" variant="contained">
    //       Sim
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};
export default SessionTimeoutDialog;
