import axios from "axios";
import conf from "../../src/utils/.params.js";
import { getSessionStorage } from "../../src/utils/sessionStorage";

import Resizer from "react-image-file-resizer";
export const resizeFile = async (file, resolution) => {
  return new Promise((resolve) => { Resizer.imageFileResizer(file, resolution, resolution, "JPEG", 80, 0, (uri) => { resolve(uri); }, "file"); });
}

export const limparNomeFoto = async (texto) => {
  return await texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_");
}

export const formatPrice = (valor) => {
  valor = valor ? valor : 0;
  return (valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
}

export const converter_data = (data) => {
  if (data) {
    return data.substr(0, 10).split("-").reverse().join("/");
  } else {
    return "";
  }
};


export const requesAmin = async (rota, metodo, data, header) => {

  try {

    const token = getSessionStorage("user") ? `Bearer ${getSessionStorage("user").token}` : false;

    if (header) {
      header.authorization = token;
    } else {
      header = { authorization: token };
    }


    let reqOptions = {
      url: conf.url() + rota,
      method: metodo,
      headers: header,
      data: data
    };

    return await axios.request(reqOptions);

  } catch (error) {
    console.log("erro request ", error.message)
    return { erro: true, msg: error.message }
  }
};


// export const requestBack = async (rota, metodo, data, header) => {

//   try {

//     let reqOptions = {
//       url: conf.url() + rota,
//       method: metodo,
//       headers: { Authorization: "Bearer " + conf.api_token() },
//       data: data
//     };

//     return await axios.request(reqOptions);

//   } catch (error) {
//     console.log("erro request ", error.message)
//     return { erro: true, msg: error.message }
//   }
// };





// export const salvarLoginAceso = () => {
//   const usuario = getSessionStorage("user");

//   defaultRequest('/log/inserirLog', { id_usuario: usuario.id_usuario, usuario: usuario.email, sistema: 'ESTÚDIO' }).then(function (response) {
//   }).catch((error) => {
//     console.log(error.message);
//     return;
//   });
// }

// export const cardTodosProdutosOfertados = () => {
//   document.getElementById("modal_referencias").classList.add('displayNone');
//   document.getElementById("cardOffer").classList.remove('displayNone');
// }


// export const atualizarHtml = (referencias, setReferencias, response) => {
//   let novo = []
//   if (response.sucesso.length > 0) {
//     referencias.forEach((element) => {
//       if (!response.sucesso.includes(element.REF)) {
//         novo.push(element)
//       }
//     });
//     setReferencias(novo)
//   }
// };

// export const atualizarHtmlTabela = (retorno, texto_total) => {
//   if (retorno.length > 0) {
//     let elemento_total = document.getElementById(`total_itens`);
//     let total = elemento_total.value;
//     retorno.forEach((e) => {
//       document.getElementById(`elemento_${e}`).remove();
//       total--;
//     });

//     elemento_total.setAttribute('value', total)
//     elemento_total.innerHTML = `${texto_total} (${total})`
//   }
// };


// export const toastRetorno = (response) => {

//   if (response.error.length > 0) {
//     response.error.forEach(e => {
//       toast.error(`${e.msg}`, { autoClose: false });
//     });
//   }
//   if (response.sucesso.length > 0) {
//     toast.success(response.sucesso.length == 1 ? `Referência aprovada com sucesso!` : `Referências aprovadas com sucesso!`);
//   }
// };


// export const handleClickTab = (e, setValueTab) => {
//   var i, tabcontent, tablinks;
//   tabcontent = document.getElementsByClassName("tabcontent");
//   for (i = 0; i < tabcontent.length; i++) {
//     tabcontent[i].style.display = "none";
//   }
//   tablinks = document.getElementsByClassName("tablinks");
//   for (i = 0; i < tablinks.length; i++) {
//     tablinks[i].className = tablinks[i].className.replace(" active", "");
//   }
//   document.getElementById(e.currentTarget.name).style.display = "block";
//   e.currentTarget.className += " active";


//   if (setValueTab) {
//     setValueTab(e.currentTarget.name == 'nao_encontrados' ? 1 : 2)
//   }
// };


// export const selTodasReferencias = (e, classe, chave, btn) => {

//   const check = e ? e.target.checked : false;
//   const marca = getSessionStorage("user").marcaSelecionada;
//   chave = `${chave}_${marca}`

//   let elementos = document.getElementsByClassName(classe);
//   let ids = [];

//   for (const el of elementos) {
//     // APENAS PARA SELECIONAR TODOS O CHECKBOX
//     if (check && !el.checked) {
//       el.click();
//     } else if (!check && el.checked) {
//       el.click();
//     }

//     // MONTAR O ARRAY QUE VAI SER SALVO
//     if (check) { ids.push(el.value); }
//   }

//   const element = document.getElementById(btn);

//   if (element) {
//     if (check) {
//       sessionStorage.setItem(chave, JSON.stringify(ids));
//       element.classList.add('btn-ativo');
//     } else {
//       element.classList.remove('btn-ativo');
//       sessionStorage.removeItem(chave);
//     }
//   }
// }



// export const selRefs = (value, chave, checked, btn) => {

//   const marca = getSessionStorage("user").marcaSelecionada;
//   chave = `${chave}_${marca}`

//   let storage = JSON.parse(sessionStorage.getItem(chave)) || [];

//   if (checked) {
//     storage.push(value);
//   } else {
//     let index = storage.indexOf(value);
//     storage.splice(index, 1);
//   }


//   if (storage.length > 0) {
//     sessionStorage.setItem(chave, JSON.stringify(storage))
//     document.getElementsByClassName(btn)[0].classList.add('btn-ativo');
//   } else {
//     sessionStorage.removeItem(chave)
//     document.getElementsByClassName(btn)[0].classList.remove('btn-ativo');
//   }

// }

// export const filiaisEstudio = (marca) => {
//   switch (marca) {
//     case 'FABULA':
//       return ["MARKETING FABULA"];
//       break;
//     case 'FARM':
//       return ["ESTUDIO FARM FB", "FOTO ECOMMERCE FARM"];
//       break;
//     case 'MARIA FILO':
//       return ["EST MOST MKT"];
//       break;
//     default:
//       return ["STUDIO SOMA AAF & FFF"];
//       break;
//   }
// }


// export const habilitarBotoes = (tipo) => {

//   let [btnEmail] = document.getElementsByClassName('btn-enviarEmail');
//   let [btnData] = document.getElementsByClassName('btn-dataPricker');
//   let [btnComentario] = document.getElementsByClassName('btn-comentario');

//   if (window.innerWidth > 959) {
//     btnComentario.classList.remove("icones-cinza");

//     // aba principal = 1
//     if (tipo == 1) {
//       btnEmail.classList.remove("icones-cinza");
//       btnData.classList.remove("icones-cinza");
//     }
//   } else {
//     btnComentario.classList.remove("icones-cinza");
//     btnComentario.classList.add("icones-black");
//     if (tipo == 1) {
//       btnEmail.classList.remove("icones-cinza");
//       btnEmail.classList.add("icones-black");
//       btnData.classList.remove("icones-cinza");
//       btnData.classList.add("icones-black");
//     } else {
//       btnEmail.classList.remove("icones-black");
//       btnData.classList.remove("icones-black");
//       btnEmail.classList.add("icones-cinza");
//       btnData.classList.add("icones-cinza");
//     }
//   }
// }


// export const desabilitarBotoes = () => {
//   let [btnEmail] = document.getElementsByClassName('btn-enviarEmail');
//   let [btnData] = document.getElementsByClassName('btn-dataPricker');
//   let [btnComentario] = document.getElementsByClassName('btn-comentario');
//   btnEmail.classList.add("icones-cinza");
//   btnData.classList.add("icones-cinza");
//   btnComentario.classList.add("icones-cinza");
// }

// export const formataBotoes = (tipoClasse) => {
//   let [tabMobile] = document.getElementsByClassName('tab-mobile');
//   let [boxTabs] = document.getElementsByClassName('box-tabs');
//   let [dataPickerEmail] = document.getElementsByClassName('data-picker-email');
//   let [flexTab] = document.getElementsByClassName('flex-tab');


//   if (tipoClasse == "adicionar-estilo") {
//     tabMobile.classList.add('tab-mobile-formata');
//     boxTabs.classList.add('box-tabs-formata');
//     boxTabs.classList.remove('icones-esconder');
//     dataPickerEmail.classList.add('data-picker-email-formata');
//     flexTab.classList.add("flex-tab-mobile");
//   }
//   else {
//     tabMobile.classList.remove('tab-mobile-formata');
//     boxTabs.classList.remove('box-tabs-formata');
//     dataPickerEmail.classList.remove('data-picker-email-formata');
//     boxTabs.classList.add('icones-esconder');
//   }
// }


// export const ordenarDados = (rows, boleano, campo) => {

//   return rows.sort(function (a, b) {
//     return boleano ? a[campo] - b[campo] : b[campo] - a[campo];
//   });
// }

// export const filtrarDados = async (rows, campo, valor) => {

//   if (typeof valor == 'string' && valor == 'true') {
//     valor = true;
//   } else if (typeof valor == 'string' && valor == 'false') {
//     valor = false;
//   }


//   if (campo == 'availablequantity') {
//     return await rows.filter(item => {
//       return valor == 0 ? item[campo] == 0 : item[campo] > 0;
//     })
//   } else {
//     return await rows.filter(item => { return item[campo] == valor })
//   }


// }

// export const formatPrice = (valor) => {
//   valor = valor ? valor : 0;
//   return (valor).toLocaleString('pt-BR', {
//     style: 'currency',
//     currency: getSessionStorage("user").marcaSelecionada == 'FARM GLOBAL' ? 'USD' : 'BRL'
//   });
// }

// export const converter_data = (data) => {
//   if (data) {
//     return data.substr(0, 10).split("-").reverse().join("/");
//   } else {
//     return "";
//   }
// };

// export const nome_pacote = (pacote, tipo) => {

//   if (pacote) {

//     if (((pacote.indexOf("-") != -1) || (pacote.indexOf("/") != -1)) && pacote.length == 10) {
//       if (tipo == 'pt-BR') {
//         return pacote.substr(0, 10).split("-").reverse().join("/");
//       } else {
//         return pacote.substr(0, 10).split("/").reverse().join("-");
//       }
//     } else {
//       return pacote
//     }
//   } else {
//     return "";
//   }
// };

// function padTo2Digits(num) {
//   return num.toString().padStart(2, '0');
// }

// export const format_data_full = (date) => {
//   date = new Date(date)
//   return (
//     [
//       padTo2Digits(date.getDate()),
//       padTo2Digits(date.getMonth() + 1),
//       date.getFullYear()
//     ].join('/') +
//     ' ' +
//     [
//       padTo2Digits(date.getHours()),
//       padTo2Digits(date.getMinutes()),
//       padTo2Digits(date.getSeconds()),
//     ].join(':')
//   );
// }


// export const format_data = (data) => {
//   if (data) {
//     return data.split('-').reverse().join('/')
//     // data = new Date(data)
//     // return data.toLocaleDateString('pt-BR')
//   } else {
//     return "";
//   }
// };


// export const marcasUtilizadas = async (dados) => {
//   let marcasPermitidas = [];
//   const marcasAtuais = [
//     "ANIMALE",
//     "FOXTON",
//     "FABULA",
//     "FARM",
//     "OFF",
//     "BYNV",
//     "MARIA FILO",
//     "CRIS BARROS",
//     "FARM GLOBAL",
//   ];
//   dados.forEach((element) => {
//     if (marcasAtuais.includes(element)) {
//       marcasPermitidas.push(element);
//     }
//   });

//   return marcasPermitidas = marcasPermitidas.sort();
// };


// export const objToCSV = async (objArray) => {
//   const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
//   let str = `${Object.keys(array[0]).map((value) => `"${value}"`).join(",")}` + "\r\n";
//   let novo = array.reduce((str, next) => {
//     str += `${Object.values(next).map((value) => `"${value}"`).join(",")}` + "\r\n";
//     return str;
//   }, str);

//   let data = new Date();
//   let name = `Atributos_vtex_${getSessionStorage("user").marcaSelecionada}_${data.getDate()}-${(data.getMonth() + 1)}-${data.getFullYear()}`;

//   let hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(novo);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = name + ".csv";
//   hiddenElement.click();
// };


// export const defaultRequest = async (rota, params = null, setLoading = null) => {

//   let valoresSelect = sessionStorage.getItem('params');
//   if (valoresSelect) {
//     valoresSelect = JSON.parse(valoresSelect);
//     params.filtro_colecao = valoresSelect.filtro_colecao ? valoresSelect.filtro_colecao : false;
//     params.filtro_lancados = valoresSelect.filtro_lancados ? valoresSelect.filtro_lancados : false;
//     params.filtro_marca = valoresSelect.filtro_marca ? valoresSelect.filtro_marca : false;
//     params.filtro_canal = valoresSelect.filtro_canal ? valoresSelect.filtro_canal : false;
//     sessionStorage.setItem('params', JSON.stringify(params));
//   }

//   if (setLoading) { setLoading(true); }

//   if (getSessionStorage("user"))
//     params.marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");

//   let reqOptions = {
//     url: conf.url() + rota,
//     method: "POST",
//     headers: {
//       Accept: "*/*",
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + conf.api_token(),
//     },
//     data: params,
//   };
//   return await axios.request(reqOptions).then(function (response) {
//     if (setLoading) { setLoading(false); }
//     return response.data;
//   });
// };

// export const requestBack = async (
//   route,
//   params,
//   setDados = null,
//   setPage = null,
//   setErrorMessage = null,
//   setLoading = null
// ) => {
//   if (!route && !params) {
//     return;
//   }

//   params.marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");

//   if (setDados) setDados("");

//   let reqOptions = {
//     url: conf.url() + route,
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + conf.api_token(),
//       Accept: "*/*",
//       "Content-Type": "application/json",
//       "Cache-Control": "no-cache",
//       "Access-Control-Allow-Origin": true,
//       "Access-Control-Allow-Headers":
//         "https://farol-estudio-frontend.storage.googleapis.com",
//     },
//     data: params,
//   };

//   axios.defaults.headers.post["Content-Type"] =
//     "application/json;charset=utf-8";
//   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

//   return await axios
//     .request(reqOptions)
//     .then(function (response) {
//       //console.log(response.data);
//       if (setLoading) setLoading(false);

//       if (setDados) setDados(response.data);
//       if (response.data.data.length == 0) {
//         if (setErrorMessage)
//           setErrorMessage("Nenhum resultado para a consulta!");
//         return;
//       }

//       if (setPage) setPage(response.data.page);
//       if (setErrorMessage) setErrorMessage(false);
//       return { error: false, msg: response.data };
//     })
//     .catch(function (error) {
//       // console.log(error.message);

//       if (setErrorMessage) setErrorMessage("Nenhum resultado para a consulta!");
//       return { error: true, msg: error.message };
//     });
// };


// export const montarTelaPrincialGestao = async (response, setDados, setLoading) => {
//   try {

//     let dados_geral = response.dados_geral;

//     let nao_ofertados_receita = dados_geral.skus_ecom_nao_vendidos_receita + dados_geral.total_operacao_receita;
//     let nao_ofertados = dados_geral.skus_ecom_nao_vendidos + dados_geral.total_operacao_skus;

//     let porcentagem = nao_ofertados_receita == 0 ? 0 : ((nao_ofertados_receita * 100) / (nao_ofertados_receita + dados_geral.skus_vendidos_receita)).toFixed(1);
//     let ofertadosPorcentagem = porcentagem == 0 ? 0 : (100 - porcentagem).toFixed(1);

//     let ret = {};
//     ret.colecoes = response.colecoes;
//     ret.atualizado = response.atualizado;
//     ret.qtdSkusTotal = nao_ofertados + dados_geral.skus_vendidos;

//     ret.receitaTotal = nao_ofertados_receita + dados_geral.skus_vendidos_receita;
//     ret.todosProdutosOfertados = ofertadosPorcentagem < 100 ? false : true;
//     ret.ofertadosPorcentagem = ofertadosPorcentagem;
//     ret.naoOfertadosPorcentagem = porcentagem;
//     ret.ofertados = dados_geral.skus_vendidos;
//     ret.ofertadosReceita = dados_geral.skus_vendidos_receita;
//     ret.naoOfertados = nao_ofertados;
//     ret.naoOfertadosReceita = nao_ofertados_receita;
//     ret.top_6 = response.dados_card.splice(0, 6);

//     let dados_marketplace = response.dados_marketplace;
//     ret.naoOfertadosMarketplace = dados_marketplace.pendentes_marketplace + dados_marketplace.nao_encontrados_marketplace;
//     ret.naoOfertadosMarketplaceReceita = dados_marketplace.pendentes_marketplace_receita + dados_marketplace.nao_encontrados_marketplace_receita;
//     ret.ofertadosMarketplace = dados_marketplace.ofertados_marketplace;
//     ret.ofertadosMarketplaceReceita = dados_marketplace.ofertados_marketplace_receita;
//     ret.totalMarketplace = dados_marketplace.pendentes_marketplace + dados_marketplace.nao_encontrados_marketplace + dados_marketplace.ofertados_marketplace;
//     ret.totalMarketplaceReceita = dados_marketplace.pendentes_marketplace_receita + dados_marketplace.nao_encontrados_marketplace_receita + dados_marketplace.ofertados_marketplace_receita;

//     setDados(ret);
//     setLoading(false)


//   } catch (error) {
//     console.log(error.message)
//   }
// };


// export const montarTelaNaoOfertados = async (response, setDados, setLoading) => {

//   try {
//     let dados_card = response.dados_card;
//     let dadosOrdenadosCatalogo = await dados_card.filter(item => { return item.badge == "catalogo"; })
//     let dadosOrdenadosOperacao = await dados_card.filter(item => { return item.badge == "operacao"; })

//     let dados_geral = response.dados_geral;
//     let total = dados_geral.skus_ecom_nao_vendidos_receita + dados_geral.total_operacao_receita;
//     let porcentagem = dados_geral.skus_ecom_nao_vendidos_receita == 0 ? 0 : ((dados_geral.skus_ecom_nao_vendidos_receita * 100) / total).toFixed(1);
//     let porcentagem2 = porcentagem == 0 ? 0 : (100 - porcentagem).toFixed(1);

//     let ret = {};
//     ret.dadosOrdenados = dados_card;
//     ret.dadosOrdenadosCatalogo = dadosOrdenadosCatalogo;
//     ret.dadosOrdenadosOperacao = dadosOrdenadosOperacao;
//     ret.catalogoReceita = dados_geral.skus_ecom_nao_vendidos_receita;
//     ret.catalogo = dados_geral.skus_ecom_nao_vendidos;
//     ret.operacaoReceita = dados_geral.total_operacao_receita;
//     ret.operacao = dados_geral.total_operacao_skus;
//     ret.catalogoPorcentagem = porcentagem;
//     ret.operacaoPorcentagem = porcentagem2;
//     ret.qtdSkusTotal = dados_geral.skus_ecom_nao_vendidos + dados_geral.total_operacao_skus;
//     ret.receitaTotal = total;
//     ret.colecoes = response.colecoes;
//     ret.atualizado = response.atualizado;
//     setDados(ret)
//     setLoading(false)

//   } catch (error) {
//     console.log(error.message)

//   }
// };


// export const transformTextModal = () => {
//   return {
//     catalogo: "Critérios relaciondos à oferta através dos atributos da VTEX",
//     apenas_skus_nao_encontrados_ecom: "Ocorre quando referência_cor não foi encontrada na VTEX.",
//     apenas_estoque_ecom: "Ocorre quando referência_cor está sem estoque na VTEX (há estoque no linx, mas ele não é refletido na VTEX). Alguns exemplos são ruptura de estoque (política zera estoque) e quando não há catálogo cadastrado.",
//     apenas_estoque_imagens_ecom: "Ocorre quando referência cor está sem estoque e sem imagem na VTEX",
//     apenas_estoque_ativo_ecom: "Ocorre quando referência cor está sem estoque e não está ativo na VTEX",
//     apenas_estoque_visivel_ecom: "Ocorre quando referência cor está sem estoque e não está visível na VTEX",
//     apenas_estoque_categoria_ecom: "Ocorre quando referência cor está sem estoque e não categorizada na VTEX",
//     apenas_estoque_ativo_visivel_ecom: "Ocorre quando referência cor está sem estoque, não está ativo e não está visível na VTEX",
//     apenas_estoque_imagens_ativo_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não ativo na VTEX",
//     apenas_estoque_imagens_visivel_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não visível na VTEX",
//     apenas_estoque_imagens_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem e não categorizada na VTEX",
//     apenas_estoque_imagens_ativo_visivel_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não ativo e não visível na VTEX",
//     apenas_estoque_imagens_ativo_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não ativo e não categorizada na VTEX",
//     apenas_estoque_imagens_visivel_categoria_ecom: "Ocorre quando referência cor está sem estoque, sem imagem, não visível e não categorizada na VTEX",
//     apenas_imagens_ecom: "Ocorre quando referência cor está sem imagem na VTEX",
//     apenas_imagens_ativo_ecom: "Ocorre quando referência cor está sem imagem e não ativo na VTEX",
//     apenas_imagens_visivel_ecom: "Ocorre quando referência cor está sem imagem e não visível na VTEX",
//     apenas_imagens_categoria_ecom: "Ocorre quando referência cor está sem imagem e não categorizada na VTEX",
//     apenas_imagens_ativo_visivel_ecom: "Ocorre quando referência cor está sem imagem, não ativo e não visível na VTEX",
//     apenas_imagens_ativo_categoria_ecom: "Ocorre quando referência cor está sem imagem, não ativo e não categorizada na VTEX",
//     apenas_imagens_visivel_categoria_ecom: "Ocorre quando referência cor está sem imagem, não visível e não categorizada na VTEX",
//     apenas_imagens_ativo_visivel_categoria_ecom: "Ocorre quando referência cor está sem imagem, não ativo, não visível e não categorizada na VTEX",
//     apenas_visivel_ecom: "Ocorre quando referência cor não está visível na VTEX",
//     apenas_ativo_ecom: "Ocorre quando referência cor não está ativo na VTEX",
//     apenas_visivel_ativo_ecom: "Ocorre quando referência cor não está visível e não ativo na VTEX",
//     operacao: "Fatores relacionados à operação Omni",
//     skus_nao_vendidos_por_regra_deflator_operacao: "O deflator de marca indiponibiliza peças abaixo de X unidades na rede de lojas da marca",
//     skus_indisponivel_deflator_marca_operacao: "O deflator de seller é responsável por gerenciar uma margem de segurança do estoque, indisponibilizando, quando aplicado, X peças de um SKU do seller.",
//     skus_lojas_desligadas_operacao: "Ocorre quando há algum seller omni desligado ",
//     skus_lojas_desligadas_teto_operacao: "Ocorre quando um seller omni atinge o teto, então ele é desligado até estar novamente em condições (quantidade de pedidos abaixo do limite máximo) para operação",
//     skus_nao_estao_em_lojas_abrangentes_operacao: "SKUs que não estão sendo ofertados no site pois estão apenas em sellers não abrangentes, ou seja, sellers que não integram o catálogo do site",
//     skus_lojas_nao_conectado_digital_operacao: "SKU está em uma filial do grupo que não está configurada para venda no digital ",
//     sku_indisponivel_embalado_operacao: "SKUs embalados ficam indisponíveis para a venda online ",
//     sku_indisponivel_transito_operacao: "SKUs indisponíveis para a venda online pois estão sendo transferidos de uma filial para outra",
//     sku_indisponivel_reserva_operacao: "SKUs indisponíveis para a venda online pois estão reservados no seller",
//     somente_atacado_operacao: "SKUs indisponíveis para a venda online pois estão reservados para apenas atacado",
//     nao_encontrados_marketplace: "SKUs indisponíveis para a venda online ",
//     pendentes_marketplace: "SKUs pendentes para aprovação ",
//     ofertados_marketplace: "SKUs ofertados no marketplace ",
//     nao_ofertados_marketplace: "SKUs não ofertados no marketplace ",
//     // apenas_imagens: "texto",
//   }
// };

// export const transformTitleModal = () => {
//   return {
//     catalogo: "Catálogo",
//     apenas_skus_nao_encontrados_ecom: "Não encontrados ecommerce",
//     apenas_estoque_ecom: "Sem estoque",
//     apenas_estoque_imagens_ecom: "Sem estoque e sem imagem",
//     apenas_estoque_ativo_ecom: "Sem estoque e não ativo",
//     apenas_estoque_visivel_ecom: "Sem estoque e não visível",
//     apenas_estoque_categoria_ecom: "Sem estoque e sem categoria",
//     apenas_estoque_ativo_visivel_ecom: "Sem estoque, não ativo e não visível",
//     apenas_estoque_imagens_ativo_ecom: "Sem estoque, sem imagem e não ativo",
//     apenas_estoque_imagens_visivel_ecom: "Sem estoque, sem imagem e não visível",
//     apenas_estoque_imagens_categoria_ecom: "Sem estoque, sem imagem e sem categoria",
//     apenas_estoque_imagens_ativo_visivel_ecom: "Sem estoque, sem imagem, não ativo e não visível",
//     apenas_estoque_imagens_ativo_categoria_ecom: "Sem estoque, sem imagem, não ativo e sem categoria",
//     apenas_estoque_imagens_visivel_categoria_ecom: "Sem estoque, sem imagem, não visível e sem categoria",
//     apenas_imagens_ecom: "Sem imagem",
//     apenas_imagens_ativo_ecom: "Sem imagem e não ativo",
//     apenas_imagens_visivel_ecom: "Sem imagem e não visível",
//     apenas_imagens_categoria_ecom: "Sem imagem e sem categoria",
//     apenas_imagens_ativo_visivel_ecom: "Sem imagem, não ativo e não visível",
//     apenas_imagens_ativo_categoria_ecom: "Sem imagem, não ativo e sem categoria",
//     apenas_imagens_visivel_categoria_ecom: "Sem imagem, não visível e sem categoria",
//     apenas_imagens_ativo_visivel_categoria_ecom: "Sem imagem, não ativo, não visível e sem categoria",
//     apenas_visivel_ecom: "Não visível",
//     apenas_ativo_ecom: "Não ativo",
//     apenas_visivel_ativo_ecom: "Não visível e não ativo",
//     operacao: "Operação",
//     skus_nao_vendidos_por_regra_deflator_operacao: "Indisponibilidade devido ao deflator de seller",
//     skus_indisponivel_deflator_marca_operacao: "Indisponibilidade devido ao deflator de marcas",
//     skus_lojas_desligadas_operacao: "Omni desligado",
//     skus_lojas_desligadas_teto_operacao: "Omni desligado teto atingido",
//     skus_nao_estao_em_lojas_abrangentes_operacao: "Estoque não ofertado por sellers não abrangentes",
//     skus_lojas_nao_conectado_digital_operacao: "Estoque não conectado ao digital",
//     sku_indisponivel_embalado_operacao: "Estoque  conectado ao digital indisponível (embaladas)",
//     sku_indisponivel_transito_operacao: "Estoque  conectado ao digital indisponível (em transito)",
//     sku_indisponivel_reserva_operacao: "Estoque conectado ao digital indisponível (reservado)",
//     somente_atacado_operacao: "Estoque disponível apenas para atacado",
//     nao_encontrados_marketplace: "Não encontrados",
//     pendentes_marketplace: "Pendentes",
//     ofertados_marketplace: "Ofertados marketplace",
//     nao_ofertados_marketplace: "Não ofertados marketplace",
//     // apenas_imagens: "texto",
//   }
// };

// export const sendGTAGEventByID = (id, email) => {
//   if (id === "skus_indisponivel_deflator_marca_operacao") {
//     SendGTAGEvent('card_1_receita', 'nao_ofertados', 'click', email);
//   }

//   if (id === "apenas_visivel_ecom") {
//     SendGTAGEvent('card_2_receita', 'nao_ofertados', 'click', email);
//   }

//   if (id === "apenas_imagens_visivel_ecom") {
//     SendGTAGEvent('card_3_receita', 'nao_ofertados', 'click', email);
//   }
// }

// export const cardGenericoHelper = async (iten) => {

//   let nome = Object.keys(iten)[0];
//   let titulo = nome.replace(/_/g, " ").replace(/ecom/g, "").replace(/operacao/g, " ");
//   let nome_receita = Object.keys(iten)[1];
//   let tipo = nome.indexOf('apenas') != -1 ? 'Catalogo' : 'Operação';

//   return {
//     nome: nome,
//     titulo: titulo,
//     nome_receita: nome_receita,
//     tipo: tipo,
//   }
// };


// export const changeParams = async (e, params, setParams, tipo, colecoesSelecionadas = false) => {
//   if (tipo) {
//     let filtro_colecao = colecoesSelecionadas[0] == "Todas" ? false : colecoesSelecionadas
//     params.filtro_colecao = filtro_colecao;
//     setParams(params);
//     sessionStorage.setItem('params', JSON.stringify(params));
//   } else {
//     e.preventDefault();
//     params[e.target.name.toLowerCase()] = e.target.value;
//     setParams(params);
//     sessionStorage.setItem('params', JSON.stringify(params));
//   }
// }


// export const changeSelectScrol = async (setDados, setLoading, route, params, tela) => {
//   defaultRequest(route, params).then(function (response) {
//     switch (tela) {
//       case 'principal':
//         montarTelaPrincialGestao(response, setDados, setLoading);
//         break;
//       case 'naoOfertados':
//         montarTelaNaoOfertados(response, setDados, setLoading);
//         break;
//       default:
//         setDados(response)
//         setLoading(false)
//         break;
//     }

//   })
// }

// export const buscaModal = async (termo, produtos) => {
//   return produtos.filter(item => {
//     return item.REF == termo;
//   })
// }

// export const exportarCsv = async (objArray, name) => {
//   const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
//   let str =
//     `${Object.keys(array[0]).map((value) => `"${value}"`).join(",")}` + "\r\n";

//   let novo = array.reduce((str, next) => {
//     str += `${Object.values(next).map((value) => `"${value}"`).join(",")}` + "\r\n";
//     return str;
//   }, str);

//   let hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(novo);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = name + ".csv";
//   hiddenElement.click();
// };
