import { Buffer } from 'buffer';

export const setSessionStorage = (label, value) => {
  let stringEncode = Buffer.from(JSON.stringify(value)).toString("base64");
  sessionStorage.setItem(label, stringEncode);
};

export const getSessionStorage = (label) => {
  const jsonUser = sessionStorage.getItem(label);

  if (!jsonUser) return null;

  let stringDecode = Buffer.from(jsonUser, "base64").toString("utf-8");
  return JSON.parse(stringDecode);
};

export const removeSessionStorage = (label) => {
  sessionStorage.removeItem(label);
};

export const validSession = (label) => {
  let sessao = getSessionStorage(label);
  const path = window.location.pathname;
  if (sessao) {
    return true
  } else if (path.indexOf("admin") !== -1) {
    window.location.href = "/login"
  }
};
