import React, { useState, useEffect } from 'react';
import "./produtos.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";
import { requesAmin, formatPrice, resizeFile } from "../../../utils/functions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';
import MenuLateral from "../../../componentes/menuLateral/menuLateral";
import ModalZoomImage from "../../../componentes/ModalZoomImage/index";
import ModalFotos from "../../../componentes/ModalFotos/ModalFotos";

export default function Produtos() {
    const [load, setLoad] = useState(true);
    const [dados, setDados] = useState(false);
    const [modif, setModif] = useState(false);

    useEffect(() => {
        requesAmin("/admin/produto/listar", "GET").then((res) => {
            setDados(res.data.dados)
            setLoad(false)
        }).catch((err) => { console.log(err) })
    }, [modif]);


    const integracao = (ref) => {
        if (load)
            return


        setLoad(true)
        const idToast = toast.loading("Aguarde um pouco...");

        requesAmin("/admin/integracao/produto", "POST", { ref: ref }, false).then((res) => {
            toast.update(idToast, { render: "Produto integrado com o site!", type: 'success', isLoading: false, autoClose: 10000 });
            setLoad(false);
            document.getElementById(`id_site_${ref}`).innerHTML = res.data.msg;

        }).catch((err) => {
            console.log(err)
            toast.update(idToast, { render: err.message, type: "warning", isLoading: false, autoClose: 10000 });
        })
    }


    const remover = (id_site) => {
        if (load)
            return

        setLoad(true)
        const idToast = toast.loading("Aguarde um pouco...");

        requesAmin("/admin/integracao/produto/remover", "POST", { id_site: id_site }, false).then((res) => {
            toast.update(idToast, { render: "Produto removido com sucesso!", type: 'success', isLoading: false, autoClose: 10000 });
            setLoad(false);
            setModif(Math.random(9999));
        }).catch((err) => {
            console.log(err)
            toast.update(idToast, { render: err.message, type: "warning", isLoading: false, autoClose: 10000 });
        })
    }

    return (<>
        <MenuLateral conteudo={

            <div className="laterais altura-tela">

                <TituloSeparacao texto={"Listagem de Produtos"} />
                <div className="row box-lancamento-vendas" >
                    <div className="col s12">
                        <table class="responsive-table striped centered">
                            <thead>
                                <tr>
                                    <th>REF</th>
                                    <th>ID SITE</th>
                                    <th>PRODUTO</th>
                                    <th>COR</th>
                                    <th>TAMANHOS</th>
                                    <th>VALOR</th>
                                    <th>Promocional</th>
                                    <th>FOTO</th>
                                    <th>Add Fotos</th>
                                    <th>Integrar</th>
                                    <th>Remover Site</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados &&
                                    dados.map((item) => {
                                        return <tr id={`${item.REF}${item.NOME}${item.TIPO_TECIDO}`} >
                                            <td>{item.REF}</td>
                                            <td id={`id_site_${item.REF}`}>{item.ID_SITE}</td>
                                            <td>{item.NOME} <div >{item.TIPO_TECIDO}</div ></td>
                                            <td>{item.COR}</td>
                                            {/* <td>{item.TAMANHOS.join("  ")}</td> */}
                                            <td>
                                                <table class="responsive-table striped centered">
                                                    <tbody>
                                                        {item.TAMANHOS.map((item2) => {
                                                            return <tr className='text-table' >  <td>{item2}</td>  </tr>
                                                        })}
                                                    </tbody>
                                                </table>


                                            </td>
                                            <td>{formatPrice(item.PRECO)}</td>
                                            <td>{formatPrice(item.PROMOCIONAL)}</td>
                                            <td><ModalZoomImage id={item.REF} /></td>
                                            <td > <ModalFotos id={item.REF} /> </td>
                                            <td onClick={(e) => integracao(item.REF)}>  <i class="material-icons icone-integrar cursor">device_hub</i> </td>
                                            <td onClick={(e) => remover(item.ID_SITE)}>  <i class="material-icons icone-remover cursor">delete_forever</i> </td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>

                    </div >
                </div >

            </div >
        } />
    </>);
};