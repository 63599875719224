import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';

import './theme/css/style.css';
import './theme/css/font-awesome.min.css';
// import './theme/css/animate.css';
import './theme/css/linearicons.css';


// import './theme/css/responsive.css';
// import './theme/css/bootstrap.min.css';


import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

