import React, { useState, useEffect } from 'react';
import "./cadastroAtributo.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";

// import Rodape from "../../../componentes/rodape/rodape";
import { requesAmin } from "../../../utils/functions";
// import { getSessionStorage } from "../../../utils/sessionStorage.js";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MenuLateral from '../../../componentes/menuLateral/menuLateral.js';

export default function CadastroAtributo() {

    // const [produtos, setProdutos] = useState(false);
    const [classe, setClasse] = useState('m4');

    // useEffect(() => {
    // }, []);
        

    const chageTipo = async (e) => {
        setClasse(e.target.value === 'PRODUTO' ? "m3" : 'm4')
    }

    const inserir = async (e) => {

        var element = document.getElementById("tipo_atributo");
        var tipo_atributo = element.options[element.selectedIndex].value;
        var nome_atributo = document.getElementById("nome");
        var descricao_atributo = document.getElementById("descricao");
        var categoria = document.getElementById("categoria");


        const idToast = toast.loading("Aguarde um pouco...")

        let data = { tipo_atributo: tipo_atributo, nome: nome_atributo.value, descricao: descricao_atributo.value, categoria: categoria ? categoria.value : false };

        requesAmin("/admin/produto/cadastro/atributo", "POST", data, false).then((res) => {
            toast.dismiss(idToast);

            if (res.erro) {
                toast.error(res.msg);
                return
            }

            toast.success(res.data.msg);
            nome_atributo.value = '';
            descricao_atributo.value = '';
            if (categoria)
                categoria.value = ''

        }).catch((err) => {
            console.log(err.message)
            toast.error(err);
        })

    }

    return (<>
        <MenuLateral conteudo={
            <div className="laterais altura-tela">
                <TituloSeparacao texto={"Cadastro de Atributo"} />

                <div className="row box-lancamento-vendas" >

                    <div className="col s12 m-t-30">

                        <div className={`m-t-15 col s12 ${classe} m-10-lancamento-vendas`}>

                            <select className='no-autoinit select-formatado' id='tipo_atributo' onChange={chageTipo}>
                                <option value={''} selected disabled>Atributo</option>
                                <option value="PRODUTO">Produto</option>
                                <option value="TIPO_TECIDO">Tipo de tecido</option>
                                <option value="TAMANHO">Tamanho</option>
                                <option value="COR">Cor</option>
                                <option value="FORMA_PAGAMENTO">Forma de pagamento</option>
                            </select>

                        </div>

                        <div class={`input-field col s6 ${classe}`}>
                            <input id="nome" type="text" className="validate" />
                            <label for="nome">Nome</label>
                        </div>

                        <div class={`input-field col s6 ${classe}`}>
                            <input id="descricao" type="text" className="validate" />
                            <label for="descricao">Descrição</label>
                        </div>

                        {classe !== "m4" && <>
                            <div class={`input-field col s6 ${classe}`}>
                                <input id="categoria" type="text" className="validate" />
                                <label for="categoria">Categoria</label>
                            </div>

                        </>}

                        <div class="input-field col s12">
                            <label class="waves-effect waves-light btn w-100 cursor" onClick={inserir}>Adicionar</label>
                        </div>
                    </div>



                </div>

            </div >
        } />
    </>);

};