import React, { useContext } from "react";
import "./fotoUpload.css"
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
export default function FotoUpload({ item, handleRemoveItem }) {

  return (<>
    <div className={`box-foto-upload `}>
      {/* <div className='close-icones-fotos'>
        <i class="material-icons cursor icon-close-foto-upload" onClick={handleRemoveItem}>close</i>
      </div> */}
      <img src={item.url} className="img-foto-upload" />
      <p className={`nome-foto-upload`} >{item.name}</p>
    </div>
  </>);
}