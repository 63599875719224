import "./login.css";
// import * as React from "react";
// import { requesAmin } from "../../../utils/functions";
// import { setSessionStorage } from "../../../utils/sessionStorage";
// import { toast } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";

import { useContext, useState } from "react";
import { UserContext } from "../../../Contexts/UserContext";


export default function Login() {
    const { login } = useContext(UserContext);
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');

    const handleKeyDown = (e) => { if (e.key === 'Enter') { handleClick(); } }

    const handleClick = (e) => {
        login(document.getElementById('usuario').value, document.getElementById('senha').value);
    };


    return (<>
        <div className="altura-tela">
            <div className="container-login">
                <div className="card card-login box-login">
                    <div className="card-content">
                        <span className="card-title title-login">Entrar</span>
                        <div className="input-field">
                            <input id="usuario" type="text" autofocus className="validate" onKeyDown={handleKeyDown} />
                            <label for="usuario">Usuário</label>
                        </div>
                        <div className="input-field">
                            <input id="senha" type="password" className="validate" onKeyDown={handleKeyDown} />
                            <label for="senha">Senha</label>
                        </div>
                        <div className="input-field m-t-30">
                            <span className="w-100 waves-effect waves-light btn-large cursor" onClick={handleClick}  >Login</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};
