import React, { useEffect } from 'react';
import "./index.css";
import { useNavigate } from "react-router";


export default function ModalZoomImage({ id }) {

    const id_novo = `${id}_zoomImagem`;
    const btnOpen = (e) => { document.getElementById(id_novo).classList.remove('display-none') };

    const btnClose = (e) => { document.getElementById(id_novo).classList.add('display-none') };


    const foto = `https://storage.googleapis.com/move-on-fotos-produtos/${id}/1.JPEG`

    return (
        <>
            <img onClick={btnOpen} alt="img produto" src={foto} className="img-listagem-produtos cursor" />

            <div id={id_novo} class="modal display-none">
                <div class="modal-content">
                    <div onClick={(e) => btnClose(e.target)} class="close">&times;</div>

                    <img alt="img produto" src={foto} className=" " />
                </div>

            </div>
        </>
    );
};
