import "./modalFotos.css";
import React, { useState } from "react";
import FotoUpload from "../../componentes/FotoUpload/FotoUpload";
import { resizeFile } from "../../utils/functions";
import conf from "../../utils/.params.js";
import { getSessionStorage } from "../../utils/sessionStorage";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';

export default function ModalFotos({ id }) {
  const id_novo = `${id}_fotos`;
  const id_input = `selecao-arquivo_${id}`;

  const [uploadFotos, setUploadFotos] = useState([]);

  const handleChangeInputFotos = async () => {

    const selectedFile = document.getElementById(id_input).files;
    let arr = [];

    for (let img of selectedFile) {

      arr.push({
        url: URL.createObjectURL(img),
        name: img.name,
        file: img,
      });
    }

    setUploadFotos(arr);
  };


  const handleEnviarFotos = async () => {

    if (uploadFotos.length === 0) {
      return;
    }
    let btn_add_fotos = document.getElementById('btn_add_fotos');
    btn_add_fotos.setAttribute('disabled', true);
    const idToast = toast.loading("Aguarde um pouco...");
    const token = getSessionStorage("user") ? `Bearer ${getSessionStorage("user").token}` : false;

    let config = {
      method: "post",
      url: conf.url() + `/admin/produto/foto/adiconar`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": token,
        "ref": id
      },
    };

    var formData = new FormData();

    var contador = 0;
    for (let obj of uploadFotos) {
      contador++;
      const image = await resizeFile(obj.file, 3200);
      let nomeFoto = image.name;

      formData.append("files", image, nomeFoto);

      if (contador % 5 === 0 || contador == uploadFotos.length) {

        config.data = formData;
        await axios.request(config);

        formData = new FormData();

      }
    }

    toast.update(idToast, { render: "Fotos inseridas com sucesso!", type: 'success', isLoading: false, autoClose: 10000 });
    btnClose();
  };


  const btnOpen = (e) => { document.getElementById(id_novo).classList.remove('display-none') };

  const btnClose = (e) => {
    document.getElementById(id_novo).classList.add('display-none')
    let list = new DataTransfer();
    document.getElementById(id_input).files = list.files;
    document.getElementById(id_input).value = "";
    setUploadFotos([]);
  };

  return (
    <>
      <i class="material-icons icone-add-fotos cursor" onClick={btnOpen}>add_a_photo</i>


      <div id={id_novo} class="modal display-none">
        <div class="modal-content">
          <div class="w-100">
            <i class="material-icons cursor close2" onClick={btnClose}>close</i>
            {/* <div onClick={(e) => btnClose(e.target)} class="close">&times; </div> */}
          </div>

          <div className="box-upload-fotos">
            <label className="label-input-file" for={id_input}>
              <input
                onChange={handleChangeInputFotos}
                id={id_input}
                type="file"
                multiple
                draggable="true"
                accept="image/*"
              />
              <spam className="box-upload-fotos-2">
                <p className="texto-upload-fotos">
                  <div>
                    <i class="material-icons icone-upload-fotos" onClick={btnOpen}>add_to_photos</i>
                  </div>
                  <strong>Clique aqui </strong> para selecionar fotos <br />
                  <p className="texto-upload-fotos-2"> formatos aceitáveis: .png, .jpg, .jpeg </p>
                </p>
              </spam>
            </label>
          </div>

          <div className="row p-15" >
            {uploadFotos &&
              uploadFotos.map((item) => {
                return (
                  <div class="col s12 m2 ">
                    <FotoUpload item={item} />
                  </div>
                );
              })
            }
          </div>


          <div className="row p-15" >
            <div className="box-btn-add-foto-modal-2 p-15">
              <a onClick={handleEnviarFotos} id="btn_add_fotos" class="waves-effect waves-light btn">ADICIONAR FOTOS</a>
            </div>
          </div>



        </div>

      </div >
    </>
  );

}
