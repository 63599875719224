import { useContext, useRef, useState } from "react";
import withIdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDiaog";
import { UserContext } from "../Contexts/UserContext";
let countdownInterval;
let timeout;
const SessionTimeout = () => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const withIdleTimer = useRef(null);
  const { userLogout, id_usuario } = useContext(UserContext);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      userLogout();
    } catch (err) {
      console.error(err);
    }
  };
  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    if (id_usuario) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, 1000);
    }
  };
  return (
    <>
      <withIdleTimer
        ref={withIdleTimer}
        onActive={onActive}
        onIdle={onIdle}
        timeout={1800000}
      />
      <SessionTimeoutDialog
        logout={() => handleLogout(true)}
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        open={timeoutModalOpen}
      />
    </>
  );
};
export default SessionTimeout;
