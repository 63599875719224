import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import M from 'materialize-css';
import { ToastContainer } from 'react-toastify';

import Login from "./paginas/admin/login/login";
import Vendas from "./paginas/admin/lancamentoVendas/lancamentoVendas";
import CadastroAtributo from "./paginas/admin/cadastroAtributo/cadastroAtributo";
import Estoque from "./paginas/admin/estoque/estoque";
import CadastroProduto from "./paginas/admin/CadastroProduto/index";
import Produtos from "./paginas/admin/produtos/listagem";
import { ProtectedRoute } from "./componentes/Helpers/ProtectedRoute";

import { UserStorage } from "./Contexts/UserContext";
import SessionTimeout from "./Session/SessionTimeout";

const App = () => {

  window.scrollTo(0, 0);
  useEffect(() => { M.AutoInit(); }, []);


  return (<>

    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

    <BrowserRouter>
      <UserStorage>
        <SessionTimeout />
        <Routes>

          < Route path="/" element={<Login />} />

          <Route path="/admin/vendas" element={<ProtectedRoute> <Vendas /> </ProtectedRoute>} />
          <Route path="/admin/cadastro/atributo" element={<ProtectedRoute> <CadastroAtributo /> </ProtectedRoute>} />
          <Route path="/admin/estoque" element={<ProtectedRoute> <Estoque /> </ProtectedRoute>} />
          <Route path="/admin/cadastro/produto" element={<ProtectedRoute> <CadastroProduto /> </ProtectedRoute>} />
          <Route path="/admin/produtos" element={<ProtectedRoute> <Produtos /> </ProtectedRoute>} />

        </Routes>
      </UserStorage>
    </BrowserRouter >

  </>);
};

export default App;
