import { createContext, useState, useEffect } from "react";
import { requesAmin } from "../utils/functions";

import { setSessionStorage, removeSessionStorage, getSessionStorage } from "./../utils/sessionStorage";
// import axios from "axios";
// import conf from "../../src/utils/.params.js";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
export const UserContext = createContext();


export const UserStorage = ({ children }) => {
  const [user, setUser] = useState(getSessionStorage("user") || null);

  async function login(usuario, senha) {

    try {

      if (usuario === "" || senha === "") {
        toast.warning("Preencha todos os campos !!!");
        return;
      }

      const idToast = toast.loading("Aguarde um pouco...")


      await requesAmin("/auth/login", "GET", false, { "usuario": usuario, "senha": senha }).then((res) => {
        toast.dismiss(idToast);

        if (res.data.erro) {
          toast.error(res.data.msg);
        } else {

          const dataUser = {
            tipo: res.data.tipo,
            token: res.data.token,
            usuario: res.data.usuario,
          };

          setSessionStorage("user", dataUser);

          switch (res.data.tipo) {
            case 1:
            case 2:
              window.location.href = "/admin/vendas";
              break;
            case 4:
              window.location.href = "/admin/estoque";
              break;
            default:
              window.location.href = "/";
              break;
          }

        }
      })


    } catch (error) {
      console.log(error.message)
      return;
    }
  }

  async function userLogout() {
    removeSessionStorage("user");
    window.location.href = "/login";
  }

  let value = { ...user, login, userLogout };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
