import * as React from "react";
import "./tituloSeparacao.css";

export default function TituloSeparacao({ texto }) {
    return (
        // <div className="row">
        <div className="box-titulo-separacao">
            <div className="tam-40 borda-titulo-separacao"> </div>
            <div className="tam-20 texto-titulo-separacao"> {texto}</div>
            <div className="tam-40 borda-titulo-separacao"></div>
        </div>
        // </div>
    );
};
