import React, { useState, useEffect } from 'react';
import "./index.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";
import { requesAmin, formatPrice } from "../../../utils/functions";
import TopoPreco from "../../../componentes/topoPreco/topoPreco";
import "react-toastify/dist/ReactToastify.css";
import MenuLateral from "../../../componentes/menuLateral/menuLateral";
import ModalZoomImage from "../../../componentes/ModalZoomImage/index";

export default function Preco() {

    const [load, setLoad] = useState(true);
    const [dados, setDados] = useState(false);

    useEffect(() => {
        requesAmin("/admin/produto/preco/consultar", "GET").then((res) => {
            setDados(res.data.dados)
            setLoad(false)
        }).catch((err) => { console.log(err) })
    }, [load]);


    return (<>
        <MenuLateral conteudo={

            <div className="laterais altura-tela">
                <TituloSeparacao texto={"Cadastrar produto"} />
                <TopoPreco />

                <TituloSeparacao texto={"Listagem de Estoque"} />
                <div className="row box-lancamento-vendas" >
                    <div className="col s12">
                        <table class="responsive-table striped centered">
                            <thead>
                                <tr>
                                    <th>PRODUTO</th>
                                    <th>TIPO</th>
                                    <th>COR</th>
                                    <th>VALOR</th>
                                    <th>PROMOCIONAL</th>
                                    <th>FOTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados &&
                                    dados.map((item) => {

                                        return <tr id={item.id} >
                                            <td>{item.nome}</td>
                                            <td>{item.tipo_tecido}</td>
                                            <td>{item.cor}</td>
                                            <td>{formatPrice(item.preco)}</td>
                                            {/* <td>{formatPrice(item.preco - item.preco * (item.desconto / 100))} ({item.desconto})%</td> */}
                                            <td>{formatPrice(item.promocional)} </td>
                                            <td><ModalZoomImage id={item.ref} /></td>
                                            {/* <td><img alt="imagem produto" src={url} className="img-listagem-produtos materialboxed" /></td> */}
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>

                    </div >
                </div >

            </div >
        } />
    </>);
};