import React, { useState, useEffect } from 'react';
import "./lancamentoVendas.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";
// import Rodape from "../../../componentes/rodape/rodape";
import { requesAmin, formatPrice } from "../../../utils/functions";
import TopoLancamentoVendas from "../../../componentes/topoLancamentoVendas/topoLancamentoVendas";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import MenuLateral from '../../../componentes/menuLateral/menuLateral';


export default function Vendas() {
    const [vendas, setVendas] = useState(false);
    const [totalVendas, setTotalVendas] = useState(0);
    const [totalDescontos, setTotalDescontos] = useState(0);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        requesAmin("/admin/vendas/listar/dia", "GET").then((res) => {
            let total = 0;
            let descontos = 0;
            res.data.data.forEach(element => {
                total += parseFloat(element.valor);
                descontos += parseFloat(element.desconto);
            });

            setTotalVendas(formatPrice(total))
            setTotalDescontos(formatPrice(descontos))
            setVendas(res.data.data)
            // setLoad(false)
        }).catch((err) => { console.log(err) })
    }, [load]);


    const deletarIten = (e) => {

        const idToast = toast.loading("Aguarde um pouco...")

        let data = { id_venda: e, };

        requesAmin("/admin/vendas/deletar", "POST", data, false).then((res) => {
            toast.dismiss(idToast);

            if (res.erro) {
                toast.error(res.msg);
                return
            }

            toast.success(res.data.msg);
            setLoad(true)

        }).catch((err) => {
            console.log(err.message)
            toast.error(err);
        })
    }

    const inserirVendas = (e) => {

        let produto = document.getElementById('produto');
        let tipo_tecido = document.getElementById('tipo_tecido');
        let tamanhos = document.getElementById('tamanhos');
        let cores = document.getElementById('cores');
        let forma_pagamento = document.getElementById('forma_pagamento');
        let valor = document.getElementById('valor');
        let desconto = document.getElementById('desconto');


        const idToast = toast.loading("Aguarde um pouco...")

        let data = {
            produto: produto.value,
            tipo_tecido: tipo_tecido.value,
            tamanho: tamanhos.value,
            cor: cores.value,
            forma_pagamento: forma_pagamento.value,
            valor: valor.value,
            desconto: desconto.value
        };


        requesAmin("/admin/vendas/inserir", "POST", data, false).then((res) => {
            toast.dismiss(idToast);

            if (res.erro) {
                toast.error(res.msg);
                return
            }

            toast.success(res.data.msg);
            setLoad(true)
            window.location.reload();
        }).catch((err) => {
            console.log(err.message)
            toast.error(err);
        })

    }

    return (<>
        {/* <MenuLateral conteudo={ */}
        <div className="laterais altura-tela">
            <TituloSeparacao texto={"Lançamento de vendas"} />
            <TopoLancamentoVendas inserirVendas={inserirVendas} />

            <div className="align-center box-valores">
                <p className='text-valores'>Total: {totalVendas}</p>
                <p className='text-valores'>Descontos: {totalDescontos}</p>
            </div>

            <div className="row box-lancamento-vendas" >
                <div className="col s12">
                    <ul class="collection with-header">
                        {/* <li class="collection-header"> */}
                        {/*     <div className="align-center box-valores">
                                <p className='text-valores'>PIX:{100}</p>
                                <p className='text-valores'>Débito:{100}</p>
                                <p className='text-valores'>Crédito:{100}</p>
                                <p className='text-valores'>Parcela:{100}</p>
                                <p className='text-valores'>Dinheiro:{100}</p>
                            </div>*/}
                        {/* </li> */}

                        {vendas &&
                            vendas.map((item) => {
                                return <li class="collection-item align-center iten-venda"><p className='text-justify'>
                                    {item.nome} {item.tipo_tecido} {item.tamanho} {item.cor} {item.forma_pagamento} {item.valor}  -{item.desconto}</p>
                                    <i class="material-icons icone-remover cursor" onClick={() => deletarIten(item.id)} >delete_forever</i>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>

        </div >


        {/* } /> */}
    </>);
};